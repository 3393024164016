import type { OngoingSale, UpcomingSale } from '~/types/api/promotion'

type Partner = 'gojek' | 'grab' | 'kredivo' | 'gopay-later'

// TODO: Refactor this to use `pinia`
export default function usePromo() {
  const partner = useCookie<Partner | undefined>('partner', {
    maxAge: 60 * 60 * 24,
    secure: true,
    sameSite: 'strict',
    default: undefined,
  })

  const ongoingSale = useAsyncData(
    'ongoing-sale',
    () => useNuxtApp().$api.product.getOngoingSales(),
    {
      default: () =>
        ({
          endAt: new Date(0),
          groups: [],
        }) as OngoingSale,
      getCachedData: (key, nuxt): OngoingSale =>
        nuxt.payload.data[key] || nuxt.static.data[key],
      dedupe: 'defer',
    },
  )

  const upcomingSale = useAsyncData(
    'upcoming-sale',
    () => useNuxtApp().$api.product.getUpcomingSales(),
    {
      default: () =>
        ({
          startAt: new Date(0),
          endAt: new Date(0),
          groups: [],
        }) as UpcomingSale,
      getCachedData: (key, nuxt): UpcomingSale =>
        nuxt.payload.data[key] || nuxt.static.data[key],
      dedupe: 'defer',
    },
  )

  const getSale = async () => {
    if ((await ongoingSale).data.value?.groups.length) {
      return 'flash-sale'
    }

    return null
  }

  return {
    ongoingSale,
    upcomingSale,
    getSale,
    partner,
  }
}
